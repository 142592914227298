<!-- 隐私协议 -->
<template>
    <div class="wrap">
        <div class="detail">
            <div class="detail-con" v-html="content">
            </div>
        </div>
    </div>
</template>

<script>
import { httpAgreementInfo } from "@/until/api";
import { type } from "os";
export default {
    data() {
        return {
            content: "",
        };
    },
    created: function () {
        httpAgreementInfo({}).then((res) => {
            if (res.code == 0) {
                this.content = res.data.privacyPact;
            }
        });
    },
    mounted: function () {
        
    },
    methods: {
    },
};
</script>

<style scoped>
.wrap {
    width: 100%;
    background: #f6f6f6;
    overflow: hidden;
}
.detail {
    background: #fff;
    padding: 30px 24px;
}
.detail-con {
    width: 100%;
}
.detail-con >>> img{
    max-width: 100%; 
    width: 100%; 
    height: auto;
}
</style>
