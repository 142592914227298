import $http from "./request"
 //获取服务器时间
 const json = {
   // "publicParameter": {
   //     "appOs": "Android",
   //     "appVersion": "1.0.0",
   //     "token": "",
   //     "user_id": ''
   // },
}

//获取协议等信息
export let httpAgreementInfo = (data = {}) =>{
   return $http.getData('member/assure/about',{...json, ...data})
}