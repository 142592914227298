<!--  -->
<template>
  <div class="wrap">
    <div class="weixin-tip" v-if="tipShow">
      <p><img src="@/assets/live_weixin.png" alt="微信打开" /></p>
    </div>
    <img class="center-img" src="@/assets/down_app_content.png" alt="" />
    <div class="con">
      <img class="con-logo" src="@/assets/down_app_logo.png" alt="" />
      <!-- <p>忆嘉人 正青春</p> -->
      <img
        @click="downiOSClick"
        class="down-btn"
        src="@/assets/down_ios_online.png"
        alt=""
        style="margin-bottom: 14px;"
      />
      <img
        @click="downAndroidClick"
        class="down-btn"
        src="@/assets/down_android_online.png"
        alt=""
        style="margin-bottom: 10px;"
      />
      <span style="color: white; ">艺校通（北京）教育科技有限公司 ｜ 京ICP备2023020508号-2A</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isAndroid: "",
      isiOS: "",
      tipShow: false,
    };
  },
  created: function () {
    let u = navigator.userAgent;
    this.isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
    this.isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    // alert('是否是Android：'+isAndroid);
    // alert('是否是iOS：'+isiOS);
    this.is_weixin();
  },
  methods: {
    downAndroidClick() {
      // if (this.isAndroid) {
      // 	window.location.href = ".apk";
      // } else if (this.isiOS) {
      // 	window.location.href = "https://apps.apple.com/app/"
      // }
      window.location.href = "https://oss.yixiaotong.com/system/yixiaotong.apk";
    },
    downiOSClick() {
        alert('敬请期待～');
    //   window.location.href = "https://apps.apple.com/app/";
    },
    is_weixin() {
      let ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        this.tipShow = true;
      } else {
        this.tipShow = false;
      }
    },
    //写一个1~10内的随机数
  },
};
</script>
<style scoped>
.wrap {
  height: 100%;
  background: url("~@/assets/down_app_01_bg.png") no-repeat;
  background-size: 100% auto;
  overflow: hidden;
  position: relative;
}
.center-img {
  display: block;
  margin: 0px auto 0;
  width: 290px;
}
.con {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: url("~@/assets/down_app_bg.png") no-repeat;
  background-size: 100% 100%;
  text-align: center;
  padding: 115px 0 6px;
}
.con-logo {
  width: 80px;
  margin-bottom: 20px;
}
.con p {
  font-size: 18px;
  color: #fff;
  line-height: 22px;
  margin: 12px 0 31px;
}
.down-btn {
  display: block;
  width: 240px;
  margin: 0 auto;
}
.weixin-tip {
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.8);
  filter: alpha(opacity=80);
  width: 100%;
  height: 100%;
  z-index: 100;
}
.weixin-tip p {
  text-align: center;
  margin-top: 10%;
  padding: 0 5%;
}
.weixin-tip img {
  width: 100%;
}
</style>