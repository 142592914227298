import Vue from 'vue'
import Vuex from 'vuex'
import {httpUserInfo} from '@/until/api'
Vue.use(Vuex);
const store = new Vuex.Store({
    state:{
        language:sessionStorage.language?sessionStorage.language:'ch',
        token:sessionStorage.token?sessionStorage.token:'',
        userMes:sessionStorage.userMes?JSON.parse(sessionStorage.userMes):{}
    },
    getters:{
        language:state => {
            return state.language
        },
        token:state => {
            return state.token
        },
        userMes:state => {
            return state.userMes
        }
    },
    mutations:{
        changeLan(state,val){
            state.language = val
        },
        setToken(state, token){
            sessionStorage.setItem("token", token);
            state.token = token
        },
        removeToken(state){
            sessionStorage.removeItem('token');
            state.token = ''
        },
        setUserMes(state,val){
            state.userMes = val
        },
    },
    actions:{
        changeLanFun({commit},val){
            commit('changeLan',val)
        },
        setUserMes({commit},fn){
            httpUserInfo().then(res => {
                if(res.code == 0){
                    commit('setUserMes',res.data);
                    sessionStorage.setItem('userMes', JSON.stringify(res.data))
                    if(fn) fn()
                }
            })
        }
    }
})
export default store