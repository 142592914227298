<!-- 关于我们 -->
<template>
    <div class="wrap">
        <div class="detail" style="padding: 15px;">
            <img class="detail-logo" src="@/assets/about_us_logo.png" alt="">
            <div class="detail-version">版本号 v{{ version }}</div>
            <div class="detail-con" v-html="aboutCompany">
            </div>
            <div @click="callPhone" style="height: 50px; display: flex; margin-top: 20px; align-items: center; border-bottom: 1px solid #F2F2F2;">
                <img style="width: 20px;height: 20px;" src="@/assets/about_us_phone.png" alt="">
                <span style="font-size: 14px; color: #151515;">电话</span>
                <span style="font-size: 14px; color: #151515;margin-left: auto;">{{aboutPhone}}</span>
            </div>
            <div style="height: 50px; display: flex; align-items: center; border-bottom: 1px solid #F2F2F2;">
                <img style="width: 20px;height: 20px;" src="@/assets/about_us_email.png" alt="">
                <span style="font-size: 14px; color: #151515;">邮箱</span>
                <span style="font-size: 14px; color: #151515;margin-left: auto;">{{aboutEmail}}</span>
            </div>
            <div class="detail-center" style="margin-top: 20px; font-size: 15px;">
                京ICP备2023020508号-2A
            </div>
        </div>
    </div>
</template>

<script>
import { httpAgreementInfo } from "@/until/api";
import { type } from "os";
export default {
    data() {
        return {
            aboutCompany: "",
            aboutPhone: "",
            aboutEmail: "",
            type: "",
            version:""
        };
    },
    created: function () {
        httpAgreementInfo({}).then((res) => {
            if (res.code == 0) {
                this.aboutCompany = res.data.aboutCompany;
                this.aboutPhone = res.data.aboutPhone;
                this.aboutEmail = res.data.aboutEmail;
            }
        });
    },
    mounted: function () {
        this.init();
        this.version = this.$route.query.version; // 版本号
    },
    methods: {
        init(){
        },
        callPhone() {
            window.location.href = `tel:${this.aboutPhone}`
        },  
    },
};
</script>

<style scoped>
.wrap {
    width: 100%;
    background: #f6f6f6;
    overflow: hidden;
}
.detail {
    background: #fff;
    padding: 30px 24px;
}

.detail-version {
    width: 100%;
    margin-top: 12px;
    text-align: center;
    font-size: 15px;
    color: #1F2635;
}

.detail-logo{
    width: 170px; 
    height: 70px;
    display: block;
    margin: 0 auto;
}

.detail-center{
    width: 100%;
    display: block;
    text-align: center;
}

.detail-con {
    width: 100%;
    margin-top: 12px;
}
.detail-con >>> img{
    max-width: 100%; 
    width: 100%; 
    height: auto;
}
</style>
