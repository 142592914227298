import Vue from 'vue'
import VueRouter from 'vue-router'
import downApp from '@/views/downApp.vue'
import privateAgreement from '@/views/privateAgreement.vue'
import userAgreement from '@/views/userAgreement.vue'
import aboutUs from '@/views/aboutUs.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        component: downApp,
		meta:{
			title: '艺校通'
		}
    },
    {
		path: '/privateAgreement',
		name: 'privateAgreement',
		component: privateAgreement,
		meta:{
			title: '隐私协议'
		}
	},
	{
		path: '/userAgreement',
		name: 'userAgreement',
		component: userAgreement,
		meta:{
			title: '用户协议'
		}
	},
	{
		path: '/aboutUs',
		name: 'aboutUs',
		component: aboutUs,
		meta:{
			title: '关于我们'
		}
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})
router.beforeEach((to, from, next) => {
	if(to.meta.title){
		document.title = to.meta.title;
	}
    if (to.meta.needLogin) {  // 判断该路由是否需要登录权限
        if (store.state.token) {  // 通过vuex state获取当前的token是否存在
            next();
        }
        else {
            next({
                path: '/login',
                query: {redirect: to.fullPath}  // 将跳转的路由path作为参数，登录成功后跳转到该路由
            })
        }
    }
    else {
        next();
    }
})

router.afterEach((to,from,next)=>{
　　window,scrollTo(0,0)
})
export default router
