import axios from 'axios'
let baseURL;

switch (process.env.NODE_ENV) {
    case 'development':
        // baseURL = "https://api.apity.cn/yxt/api/app-api/" //这里是本地的请求
        baseURL = "https://zyhg.yixiaotong.com/yxt/api/app-api/"
        break
    case 'test':
        // baseURL = "https://api.apity.cn/yxt/api/app-api/" // 测试环境
        baseURL = "https://zyhg.yixiaotong.com/yxt/api/app-api/"
        break
    case 'production':
        baseURL = "https://zyhg.yixiaotong.com/yxt/api/app-api/" //生产环境
        break
}
const service = axios.create({
    baseURL: baseURL
});

// 添加请求拦截器
service.interceptors.request.use(
    config => {
        config.headers = {
            'Content-Type': 'application/json',
            'X-App-Os': 'web',
            'tenant-id': '1',
            // 'X-Auth-Key': '',
        };
        let token = sessionStorage.getItem('token')
        if(token && token != ''){
            config.headers['access_token'] = token
        }
        return config;
    },
    error => {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
service.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  
  return response;
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error);
});

function postData(url, data = {}) {
    return new Promise((resolve, reject) => {
        // data = JSON.stringify(data)
        service.post(url, data).then(response => {
            resolve(response.data);
        }, err => {
            reject(err);
        })
    })
}

//get封装
function getData(url, params = {}) {
    return new Promise((resolve, reject) => {
        service.get(url, {
            params: params
        })
        .then(response => {
            resolve(response.data);
        })
        .catch(err => {
            reject(err)
        })
    })
}
export default {
    postData,
    getData
}